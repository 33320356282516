import React, { Component } from "react"
import Panel from "./Panel"
import "./services-accordion.scss"

export class ServicesAccordion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0,
    }

    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index,
    }))
  }
  render() {
    const { panels } = this.props
    const { activeTab } = this.state
    return (
      <div className="accordion-container">
        <div className="container">
          <div className="row">
            <div className="services-accordion" role="tablist">
              {panels.map((panel, index) => (
                <Panel
                  key={index}
                  activeTab={activeTab}
                  index={index}
                  {...panel}
                  activateTab={this.activateTab.bind(null, index)}
                />
              ))}
            </div>
            <div className="container-gallery">
              <img
                src={
                  panels[activeTab]
                    ? `${panels[activeTab].image}`
                    : `${panels[0].image}`
                }
                className="gallery-image"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ServicesAccordion
