import React, { useState } from "react"
import ReactDOM from "react-dom"
import Img1 from "../../images/hero.jpg"

class Panel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0,
      img: { Img1 },
    }
  }

  render() {
    const { title, content, image, activeTab, index, activateTab } = this.props
    const { height } = this.state
    const isActive = activeTab === index

    return (
      <>
        <div className="panel" role="tabpanel" aria-expanded={isActive}>
          <button className="panel__label" role="tab" onClick={activateTab}>
            {title}
          </button>
        </div>
      </>
    )
  }
}

export default Panel
