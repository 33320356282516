import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"
import ServicesAccordion from "../components/Accordion/ServicesAccordion"
import "../styles/automation-and-intelligence.scss"
import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "../components/ContactForm"
import LogoCarousel from "../components/LogoCarousel"

//Images
import Divider from "../images/Blue-line.png"
import MapImg from "../images/map.png"

import { CgSmartphoneChip } from "react-icons/cg"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import { SiHomeassistant } from "react-icons/si"
import { ImCogs } from "react-icons/im"
import { GoDashboard } from "react-icons/go"

import Identity from '../images/Identity_Security.svg'
import DevSecOps from '../images/Devsecops.svg'
import DataCloud from '../images/Data_Cloud_Security.svg'
import Cyber from '../images/Cybersecurity.svg'

import TextAccordion from "../components/TextAccordion/TextAccordion"

//data for accordion

//Identity security
const panels1 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Knowing the identities of users, devices and services like software processes, is essential for access decision making in a zero-trust infrastructure, these must all be uniquely identifiable. This is one of the most important factors in determining if someone or something should be granted access to data or services. Robust identity and access management, standardising and automating crucial aspects of managing identities, authentication, and authorisation, are all key risk-reduction components in any organisation.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Even with strong password policies in place, bad employee password habits can put enterprise data and systems at risk. Employee account takeover is a compromise that occurs when attackers use stolen logins to gain access to corporate accounts. When employees reuse passwords across multiple online accounts, criminals can exploit credentials that have been exposed in third-party data breaches to access their corporate accounts.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Colonial Pipeline’s Achilles heel was a single stolen password. Attackers used that single password to access the network via an employee’s VPN connection. The resultant oil pipeline shutdown resulted in massive disruption, including widespread fuel shortages and price hikes. The ensuing ransomware payment alone was $4.4M.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//Data & Cloud Security
const panels2 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Only by identifying the business value and regulatory sensitivity of data, can organisations make intelligent, deliberate decisions on how information is consumed and managed. This insight forms the basis of an effective data governance strategy and of successful data security solutions; crucial for preventing the unauthorised access and disclosure of information. Data security can become an even greater challenge with migrations to multicloud or hybrid cloud environments. A fit for purpose cloud security solution is required to defend cloud computing systems. This includes maintaining the privacy and integrity of data across an ever-increasing spectrum of cloud infrastructure, applications, and platforms.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Cloud security is fundamentally a data problem. If your current rules-driven cloud security solution can’t scale, then Metsi can help you automate security and compliance across AWS, Azure, GCP, and private clouds.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Modernising your cloud security posture could lower your operational costs by up to 35%. Metsi can enable you to simplify and automate enterprise-wide cloud security. We deploy the correct platforms easily across the enterprise to deliver comprehensive visibility and security to Kubernetes, containers, and multi-cloud environments. This allows you to untangle security across complex infrastructure, proactively detect evolving threats, and solve compliance mandates from build time to run time—all from a unified console.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//Cyber Security
const panels3 = [
  {
    icon: "",
    title: "Summary",
    content:
      "With an ever-growing and evolving threat landscape, and no end in sight to the significant shortage of skilled cybersecurity professionals, teams face constant pressure to automate more processes reduce incident response and remediation times. An integrated cyber security defence platform needs to provide pervasive infrastructure visibility while ensuring situational awareness of all traffic and behaviours throughout the infrastructure. It must enable actionable intelligence and facilitate immediate threat investigations. A robust platform needs the scalability to provide forensic analysis on massive archives for incident investigation, and the nimbleness to adapt to changing and emerging threats.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Our team has supported our clients with the design and build of complex global SIEM and SOAR platforms and are experienced in a wide range of Cyber defence solutions including Threat Hunting, Endpoint Detection and Response (EDR), and Endpoint Data Losss Prevention (DLP). Metsi also delivers Managed Security Services, such as Managed Detection and Response (MDR) that can be fully integrated directly into your operations and Service Desk.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "The past few years have seen an explosion in remote working, providing cybercriminals with more potential targets and blind spots to exploit than ever. Endpoint Threat Detection and Response has never been more crucial, it enables an organisation to establish comprehensive endpoint visibility, improve its ability to detect and investigate suspicious activities on hosts and endpoints  and automate security incident response.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//DevSecOps
const panels4 = [
  {
    icon: "",
    title: "Summary",
    content:
      "DevSecOps embeds a deep culture of security awareness and action in the DevOps lifecycle. This approach provides teams with deep visibility into security issues during the development and operational lifecycle.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Secure continuous integration allows you to achieve fast and safe application deployments. It also has the added benefit of early, automated, and continuous discovery of vulnerabilities in both application and infrastructure code.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "With DevSecOps, you can bridge the gap between development, operations, and IT teams. DevSecOps allows for a focus on maximum velocity and efficiency in delivering secure application and infrastructure solutions. We share deep insights into security and configuration support for workloads and accounts running on public clouds. A baseline monitoring platform which is visual and searchable, provides deep analytics of your entire infrastructure,  which drives business insights.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

const AutomationAndIntelligence = () => {
  //gatsby-image query
  const securityData = useStaticQuery(graphql`
    query securityImages {
      hero: file(relativePath: { eq: "lake-mountain.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bridge: file(relativePath: { eq: "lake-sun.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      lake: file(relativePath: { eq: "lake-trees.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      forest: file(relativePath: { eq: "forest-2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Zero Trust Security"
        description="Metsi’s Zero Trust Security services enhance enterprise information security and system capability. Services include identity security, data and cloud security, cyber security and DevSecOps."
        keywords={[
          "cyber security",
          "identity security",
          "data and cloud security",
          "devsecops",
          "zero trust security",
          "digital maturity",
          "data privacy",
        ]}
        lang="en-gb"
      />

      {/********* Hero Section ***********/}
      <BackgroundImage
        fluid={securityData.hero.childImageSharp.fluid}
        className="automation-hero"
        style={{
          width: "100vw",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="row">
                <div className="inner-container-2 left">
                  <div className="droplet-blue">
                    <CgSmartphoneChip className="icon" />
                  </div>
                </div>
                <div className="inner-container-2 right">
                  <div className="column">
                    <h1>Zero Trust Security</h1>
                    <img src={Divider} alt="divider" className="divider" />
                    <p style={{ color: "#626366" }}>
                      Metsi’s Zero Trust Security services and solutions offer a
                      centralised layer of policy enforcement and management,
                      risk-mitigation and the controls to enhance identities,
                      security and system capability across multiple touchpoints
                      and requirements. Security teams require analytics such as
                      anomalous detection, machine learning, artificial
                      intelligence, and real-time data inventory and cataloguing
                      to anticipate adverse movements with speed and accuracy.
                      These analytics allow organisations to initiate
                      pre-emptive action and facilitate risk-based policy
                      decisions surrounding their assets. Never trust; always
                      verify with a Zero Trust Security approach.
                    </p>
                    {/* <ButtonB href="#services">Learn More</ButtonB> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/********* Services ***********/}
      <div className="section services" id="services">
        <div className="inner-container">
          <h2>Zero Trust Security</h2>
          <h3>Securing Enterprise IT Operations</h3>
          <p>
            Metsi accelerates Digital Maturity and Zero Trust Security for
            organisations, based on a strategic alignment to the continuously
            changing IT and business landscape our clients face.
          </p>
        </div>
        <div className="row" style={{ maxWidth: "1400px" }}>
          <div className="inner-container-3" style={{ maxWidth: "180px" }}>
            <a href="#user">
              <div className="droplet-blue">
                <img src={Identity} className="icon" />
              </div>
            </a>
            <a href="#user">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                Identity Security
              </h2>
            </a>
          </div>
          <div className="inner-container-3">
            <a href="#datacloud">
              <div className="droplet-blue">
                <img src={DataCloud} className="icon" />
              </div>
            </a>
            <a href="#datacloud">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                Data & Cloud Security
              </h2>
            </a>
          </div>
          <div className="inner-container-3">
            <a href="#cyber">
              <div className="droplet-blue">
                <img src={Cyber} className="icon" />
              </div>
            </a>
            <a href="#cyber">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                Cyber Security
              </h2>
            </a>
          </div>
          <div className="inner-container-3">
            <a href="#devsecops">
              <div className="droplet-blue">
                <img src={DevSecOps} className="icon" />
              </div>
            </a>
            <a href="#devsecops">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                DevSecOps
              </h2>
            </a>
          </div>
        </div>
      </div>

      {/********* Workflow Automation ***********/}
      <BackgroundImage
        fluid={securityData.bridge.childImageSharp.fluid}
        className="section"
        id="user"
        style={{
          width: "100vw",
          minHeight: "70vh",
          backgroundPosition: "top",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          padding: "0",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Zero Trust Security</h2>
              <h3>Identity Security</h3>
            </div>
          </div>
          <TextAccordion panels={panels1} />
        </div>
      </BackgroundImage>

      {/*********** Network Automation *************/}
      <div className="section" id="datacloud">
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Zero Trust Security</h2>
              <h3>Data & Cloud Security</h3>
            </div>
          </div>
          <TextAccordion panels={panels2} />
        </div>
      </div>
      {/*********** Cyber *************/}
      <BackgroundImage
        fluid={securityData.lake.childImageSharp.fluid}
        className="section"
        id="cyber"
        style={{
          width: "100vw",
          minHeight: "70vh",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Zero Trust Security</h2>
              <h3>Cyber Security</h3>
            </div>
          </div>
          <TextAccordion panels={panels3} />
        </div>
      </BackgroundImage>

      {/********* DevSecOps ***********/}
      <BackgroundImage
        fluid={securityData.forest.childImageSharp.fluid}
        className="section"
        id="devsecops"
        style={{
          width: "100vw",
          minHeight: "80vh",
          backgroundPosition: "top",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#000",
        }}
      >
        <div
          className="overlay section"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="outer-container">
            <div className="inner-container">
              <h2>Zero Trust Security</h2>
              <h3>DevSecOps</h3>
            </div>
          </div>
          <TextAccordion panels={panels4} />
        </div>
      </BackgroundImage>

      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1 style={{ textAlign: "center" }}>
                Have a question about our solutions?
              </h1>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AutomationAndIntelligence
