import React, { useState } from "react"
import ReactDOM from "react-dom"
import Img1 from "../../images/hero.jpg"

class Panel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0,
      img: { Img1 },
    }
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = ReactDOM.findDOMNode(this)
      const height = el.querySelector(".panel__inner").scrollHeight
      this.setState({
        height,
      })
    }, 600)
  }

  render() {
    const { title, content, image, activeTab, index, activateTab } = this.props
    const { height } = this.state
    const isActive = activeTab === index
    const innerStyle = {
      height: `${isActive ? height : 0}px`,
    }
    console.log(image)

    return (
      <>
        <div className="panel" role="tabpanel" aria-expanded={isActive}>
          <button className="panel__label" role="tab" onClick={activateTab}>
            {title}
          </button>
          <div
            className="panel__inner"
            style={innerStyle}
            aria-hidden={!isActive}
          >
            <p className="panel__content">{content}</p>
          </div>
        </div>
      </>
    )
  }
}

export default Panel
